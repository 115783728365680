export const MiniProgramType = {
  5: {
    name: '支付宝-优品租',
    appId: '2021001155666499',
    type: 'alipay'
  },
  7: {
    name: '支付宝-青年优品',
    appId: '2021002122656280',
    type: 'alipay'
  },
  1: {
    name: '支付宝-优品金融',
    appId: '2021001162602064',
    type: 'alipay'
  },
  9: {
    name: '支付宝-爱租手机',
    appId: '2021002165656015',
    type: 'alipay'
  },
  6: {
    name: '微信-优品租',
    appId: 'wx7157b7ee8d09ee9f',
    type: 'wx'
  },
  11: {
    name: '微信-租手机服务',
    appId: 'wx53955b52b39c8e35',
    type: 'wx'
  },
  128: {
    name: '支付宝-优信购',
    appId: '2021003172696755',
    type: 'alipay'
  },
  126: {
    name: '支付宝-优品商城',
    appId: '2021003122642001',
    type: 'alipay'
  },
  127: {
    name: '支付宝-优品商城分期',
    appId: '2021003124699400',
    type: 'alipay'
  },
  132: {
    name: '微信-优品商城',
    appId: 'wx2d64acb39be6b563',
    type: 'wx'
  },
  133: {
    name: '微信-优品分期',
    appId: 'wx470c54fa3ee7b944',
    type: 'wx'
  }
};