import auth from '@/utils/authentication';
import { useCallback, useEffect, useMemo, useState } from 'react';
export const routes = [{
  name: '用户管理',
  key: 'user',
  children: [{
    name: '用户列表',
    key: 'user/userList'
  }, {
    name: '贷前用户',
    key: 'user/loansBefore'
  }, {
    name: '贷中用户',
    key: 'user/loansIng'
  }, {
    name: '贷后用户',
    key: 'user/loansAfter'
  }, {
    name: '人群圈选记录',
    key: 'user/checkRecord',
    children: [{
      name: '查看详情',
      key: 'user/recordDetail',
      ignore: true
    }]
  }]
}, {
  name: '推广工具',
  key: 'generalize',
  children: [{
    name: '短信发送记录',
    key: 'generalize/smsRecord',
    children: [{
      name: '详情',
      key: 'generalize/smsRecordDetail',
      ignore: true
    }, {
      name: '发送数据概览',
      key: 'generalize/smsView',
      ignore: true
    }]
  }]
}, {
  name: '营销管理',
  key: 'marketing',
  children: [{
    name: '活动列表',
    key: 'marketing/activity-list',
    children: [{
      name: '活动详情',
      ignore: true,
      breadcrumb: false,
      key: 'marketing/activity-detail'
    }]
  }, {
    name: '活动装修',
    target: () => {
      return `${process.env.REACT_APP_H5EDITOR_URL}/page-list?token=${localStorage.getItem('token')}`;
    }
  }]
}, {
  name: '换量管理',
  key: 'exchange-flow',
  children: [{
    name: '换量落地页列表',
    key: 'exchange-flow/landing-page',
    children: [{
      name: '详情',
      ignore: true,
      breadcrumb: true,
      key: 'exchange-flow/landing-page-detail'
    }]
  }, {
    name: '友商列表',
    key: 'exchange-flow/cooperation-merchant'
  }, {
    name: '同行拒量商品映射配置',
    key: 'exchange-flow/reject-goods-mapping-config'
  }]
}, {
  name: '导流商管理',
  key: 'diversion',
  children: [{
    name: '流量跳转管理',
    key: 'diversion/traffic-jump'
  }, {
    name: '流量跳转详情',
    key: 'diversion/traffic-jump-details',
    ignore: true
  }]
}, {
  name: '系统配置',
  key: 'systemconfig',
  children: [{
    name: '短信签名配置',
    key: 'systemconfig/smsSignConfig'
  }]
}, {
  name: '401',
  key: 'exception/401',
  breadcrumb: false,
  ignore: true
}];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;

    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = userPermission => {
  const filterRoute = useCallback((routes, arr = []) => {
    if (!routes.length) {
      return [];
    }

    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;

      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }

      if (!visible) {
        continue;
      }

      if (route.children && route.children.length) {
        const newRoute = { ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);

        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route
        });
      }
    }

    return arr;
  }, [userPermission]);
  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [filterRoute]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];

    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }

    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};

export default useRoute;