// 订单状态
export const orderStatusList = [{
  label: '待审核',
  value: 0
}, {
  label: '待发货',
  value: 1
}, {
  label: '已发货',
  value: 5
}, {
  label: '已签收',
  value: 15
}, {
  label: '订单已关闭',
  value: 20
}, {
  label: '已还清',
  value: 25
}, {
  label: '已关闭',
  value: 30
}, {
  label: '待支付(免押授权)',
  value: 34
}, {
  label: '待支付(履约保证金)',
  value: 35
}, {
  label: '待签约',
  value: 40
}, {
  label: '签约退回',
  value: 45
}, {
  label: '待征信授权',
  value: 50
}, {
  label: '征信授权退回',
  value: 51
}, {
  label: '风控中',
  value: 55
}, {
  label: '待放款',
  value: 60
}, {
  label: '待放款',
  value: 65
}, {
  label: '已还清',
  value: 70
}, {
  label: '申请售后中',
  value: 100
}, {
  label: '申请售后中',
  value: 110
}, {
  label: '售后失败',
  value: 120
}, {
  label: '归还中',
  value: 200
}, {
  label: '已归还',
  value: 210
}, {
  label: '已买断',
  value: 220
}, {
  label: '租用中',
  value: 300
}, {
  label: '已关闭',
  value: 30
}, {
  label: '已续租',
  value: 320
}, {
  label: '续租中',
  value: 310
}, {
  label: '续租完成',
  value: 330
}];
export const miniTypeList = [{
  miniType: 5,
  miniTypeName: '支付宝-优品租',
  name: '支付宝-优品租',
  sort: 100
}, {
  miniType: 14,
  miniTypeName: '快手-优品租',
  name: '快手-优品租',
  sort: 100
}, {
  miniType: 1,
  miniTypeName: '支付宝-租手机优品金融',
  name: '支付宝-租手机优品金融',
  sort: 99
}, {
  miniType: 7,
  miniTypeName: '支付宝-租手机青年优品',
  name: '支付宝-租手机青年优品',
  sort: 98
}, {
  miniType: 4,
  miniTypeName: '支付宝-优品租赁',
  name: '支付宝-优品租赁',
  sort: 97
}, {
  miniType: 9,
  miniTypeName: '支付宝-这里租',
  name: '支付宝-这里租',
  sort: 96
}, {
  miniType: 6,
  miniTypeName: '微信-优品租',
  name: '微信-优品租',
  sort: 80
}, {
  miniType: 13,
  miniTypeName: '微信-优品租机',
  name: '微信-优品租机',
  sort: 80
}, {
  miniType: 8,
  miniTypeName: '微信-青年优品',
  name: '微信-青年优品',
  sort: 79
}, {
  miniType: 11,
  miniTypeName: '微信-租手机服务',
  name: '微信-租手机服务',
  sort: 78
}, {
  miniType: 10,
  miniTypeName: '微信-优品租微信公众号',
  name: '微信-优品租微信公众号',
  sort: 77
}, {
  miniType: 2,
  miniTypeName: '字节-优品租',
  name: '字节-优品租',
  sort: 60
}, {
  miniType: 3,
  miniTypeName: 'APP-优品租',
  name: 'APP-优品租',
  sort: 50
}, {
  miniType: 126,
  miniTypeName: '支付宝-优品商城',
  name: '支付宝-优品商城',
  sort: 0
}, {
  miniType: 132,
  miniTypeName: '微信-优品商城官方',
  name: '微信-优品商城官方',
  sort: 0
}, {
  miniType: 133,
  miniTypeName: '微信-优品分期',
  name: '微信-优品分期',
  sort: 0
}, {
  miniType: 134,
  miniTypeName: '字节-优品分期',
  name: '字节-优品分期',
  sort: 0
}];
export const customerTypeList = {
  1: '未下单用户',
  2: '下单未过审',
  3: '下单过审未支付',
  4: '下单过审支付后取消'
};