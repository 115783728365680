import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";

/**
 * 网络请求配置
 */
import axios from 'axios';
axios.defaults.timeout = 100000;
axios.defaults.baseURL = process.env.REACT_APP_API;
import { createHashHistory } from 'history';
const history = createHashHistory();
/**
 * http request 拦截器
 */

axios.interceptors.request.use(config => {
  config.data = config.data;
  config.headers = {
    'Content-Type': config.headers['Content-Type'] || 'application/json',
    Authorization: localStorage.getItem('token')
  };
  return config;
}, error => {
  console.log('err=>', error);
  return Promise.reject(error);
});
/**
 * http response 拦截器
 */

axios.interceptors.response.use(response => {
  return response;
}, error => {
  postMessage(error);
  console.log('请求出错：', error);
});
/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */

export function get(url, params = {}, config = {}) {
  delete params.radom;
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(response => {
      if (response.data.code === 0) {
        if (config.page) {
          resolve(response.data);
        } else {
          resolve(response.data.data);
        }
      } else {
        _Message.error(response.data.message);

        reject(response.data);
      }
    }).catch(error => {
      reject(error);
    });
  });
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data, config = {}) {
  delete data.radom;
  return new Promise((resolve, reject) => {
    axios.post(url, data, config).then(response => {
      console.log('401=>', 111); //关闭进度条

      if (response.data.code === 0) {
        resolve(response.data);
      } else {
        _Message.error(response.data.message);

        reject(response.data);
      }

      resolve(response.data);
    }, err => {
      postMessage(err);
      reject(err);
    });
  });
}
/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(response => {
      resolve(response.data);
    }, err => {
      postMessage(err);
      reject(err);
    });
  });
} //统一接口处理，返回数据

export default function (fecth, url, param) {
  return new Promise((resolve, reject) => {
    switch (fecth) {
      case 'get':
        get(url, param).then(function (response) {
          resolve(response);
        }).catch(function (error) {
          postMessage(error);
          reject(error);
        });
        break;

      case 'post':
        post(url, param).then(function (response) {
          resolve(response);
        }).catch(function (error) {
          postMessage(error);
          reject(error);
        });
        break;

      default:
        break;
    }
  });
} //失败提示

function postMessage(err) {
  console.log('postMessage=>', err);

  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        _Message.error('This is an error message!');

        break;

      case 401:
        history.replace('/exception/401?menu=false'); // window.location.href = 'https://manage.qnvipmall.com/#/dashboard';

        _Message.error('未授权，请登录');

        break;

      case 403:
        _Message.error('拒绝访问');

        break;

      case 404:
        _Message.error('请求地址出错');

        break;

      case 408:
        _Message.error('请求超时');

        break;

      case 500:
        _Message.error('服务器内部错误');

        break;

      case 501:
        _Message.error('服务未实现');

        break;

      case 502:
        _Message.error('网关错误');

        break;

      case 503:
        _Message.error('服务不可用');

        break;

      case 504:
        _Message.error('网关超时');

        break;

      case 505:
        _Message.error('HTTP版本不受支持');

        break;

      default:
    }
  }
}