import { get, post } from '@/utils/request'; //获取活动分析

export const getCustomerListApi = p => get('admin/marketing/center/customerList', p, {
  page: true
}); // 用户管理用户列表

export const getMiniTypeListApi = p => get('admin/mall/mp/order/miniTypeList', p); //小程序类型

export const getDistributionAllApi = p => get('admin/drainage/category', p); // 导流商列表

export const marketGenerateBatchSelectionApi = p => post('admin/marketing/center/generateBatchSelection', p); // 生成圈选人群

export const getBatchSelectionListApi = p => get('admin/marketing/center/batchSelectionList', p, {
  page: true
}); // 圈选记录

export const getBatchSelectionDetailApi = p => get('admin/marketing/center/batchSelectionDetail', p); // 圈选详情

export const getSmsRecordApi = p => get('admin/marketing/center/smsRecord', p, {
  page: true
}); // 短信发送记录

export const smsSubmitApi = p => post('admin/marketing/center/smsSubmit', p, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}); // 发送短信提交

export const getChannelListApi = p => get('admin/marketing/center/channelList', p); // 短信通道

export const smsCancelApi = p => post('admin/marketing/center/smsCancel', p); // 取消发送短信

export const getSmsDetailApi = p => get('admin/marketing/center/smsDetail', p); //短信详情

export const getSmsDataViewApi = p => get('admin/marketing/center/smsDataView', p, {
  page: true
}); // 数据概览

export const smsInsertSignRel = p => post('admin/marketing/center/smsInsertSignRel', p); // 新增短信通道和签名接口

export const smsQuerySignRel = p => get('admin/marketing/center/smsQuerySignRel', p, {
  page: true
}); // 通道关联签名列表

export const smsQuerySign = p => get('admin/marketing/center/smsQuerySign', p); //获取签名下拉

export const getShortUrl = p => get('admin/shortUrl/getShortUrl', p); // 获取短链