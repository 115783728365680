import moment from 'moment';
import { orderStatusList, miniTypeList, customerTypeList } from '@/utils/config';
import { MiniProgramType } from '@/utils/constants';
export function getQueryString(query, variable) {
  var vars = query.split('&');

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');

    if (pair[0] == variable) {
      return pair[1];
    }
  }

  return false;
} //下载blod文件

export function downLoad(file, fileName = '文件.xls') {
  // let blob = new Blob(["\ufeff",data]);
  // 获取heads中的filename文件名
  let downloadElement = document.createElement('a'); // 创建下载的链接

  let href = file;
  downloadElement.href = href; // 下载后文件名

  downloadElement.download = fileName;
  document.body.appendChild(downloadElement); // 点击下载

  downloadElement.click(); // 下载完成移除元素

  document.body.removeChild(downloadElement); // 释放掉blob对象

  window.URL.revokeObjectURL(href);
}
export function transformObjectToUrlencodedData(obj, prefix = false) {
  let p = [];

  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      // p.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
      p.push(key + '=' + obj[key]);
    }
  }

  let str = p.join('&');

  if (prefix && str) {
    return `?${str}`;
  } else {
    return str;
  }
}
export function getUrlObj(url) {
  var params = url.split('?')[1].split('&'); // ["name=jack", "age=1", "id=001"]

  var obj = {};
  params.map(item => {
    if (item) {
      let key = item.split('=')[0];
      let value = item.split('=')[1];
      obj[key] = value;
    }
  });
  return obj;
}
export function getOrderStatusStr(value) {
  let str = '';
  orderStatusList.forEach(item => {
    if (item.value === value) {
      str = item.label;
    }
  });
  return str;
}
export function getMiniTypeList(val) {
  let str = '';
  miniTypeList.forEach(item => {
    if (item.miniType === val) {
      str = item.name;
    }
  });
  return str;
}
export function GetSelectCustomerContent(content, showListType = true) {
  const selecoContent = decodeURIComponent(content);
  const distributionList = []; // const distributionList = useSelector((state) => state.distributionList);

  let obj = {};

  try {
    obj = JSON.parse(selecoContent || '{}');
  } catch (error) {}

  obj.quotientId = obj.quotientId || obj.latestSignedOrderQuotientId;

  if (obj.quotientId && distributionList.length) {
    distributionList.forEach(item => {
      if (obj.quotientId == item.id) {
        obj.quotientId = item.name;
      }
    });
  }

  const format = 'YYYY-MM-DD HH:mm:ss';
  let list = []; // TODO key 配置在config文件

  if (obj.realName || obj.name) {
    list.push(`姓名：${obj.realName || obj.name}`);
  }

  if (obj.mobile) {
    list.push(`手机号：${obj.mobile}`);
  }

  if (obj.channel) {
    list.push(`发送通道：${obj.channel}`);
  }

  if (obj.isSentSuccess !== undefined) {
    list.push(`是否发送成功：${obj.isSentSuccess ? '是' : '否'}`);
  }

  if (obj.registerStartTime && obj.registerEndTime) {
    list.push(`注册时间：${moment(obj.registerStartTime).format(format)}~${moment(obj.registerEndTime).format(format)}`);
  }

  if (obj.latestOrderCreateStartTime && obj.latestOrderCreateEndTime) {
    list.push(`最近下单时间：${moment(obj.latestOrderCreateStartTime).format(format)}~${moment(obj.latestOrderCreateEndTime).format(format)}`);
  }

  if (obj.originalOrderCreateStartTime && obj.originalOrderCreateEndTime) {
    list.push(`最早下单时间：${moment(obj.originalOrderCreateStartTime).format(format)}~${moment(obj.originalOrderCreateEndTime).format(format)}`);
  }

  if (obj.latestSignedOrderCreateStartTime && obj.latestSignedOrderCreateEndTime) {
    list.push(`下单时间：${moment(obj.latestSignedOrderCreateStartTime).format(format)}~${moment(obj.latestSignedOrderCreateEndTime).format(format)}`);
  }

  if (obj.latestSignedOrderRentStartTimeStart && obj.latestSignedOrderRentStartTimeEnd) {
    list.push(`租期时间：${moment(obj.latestSignedOrderRentStartTimeStart).format(format)}~${moment(obj.latestSignedOrderRentStartTimeEnd).format(format)}`);
  }

  if (obj.latestSignedOrderRentEndTimeStart && obj.latestSignedOrderRentEndTimeStart) {
    list.push(`租用到期时间：${moment(obj.latestSignedOrderRentEndTimeStart).format(format)}~${moment(obj.latestSignedOrderRentEndTimeEnd).format(format)}`);
  }

  if (obj.listType != 'inLoan') {
    if (obj.latestSignedOrderRentEndTimeStart && obj.latestSignedOrderRentEndTimeEnd) {
      list.push(`最近完结时间：${moment(obj.latestSignedOrderRentEndTimeStart).format(format)}~${moment(obj.latestSignedOrderRentEndTimeEnd).format(format)}`);
    }
  }

  if (obj.customerType) {
    list.push(`用户类型：${customerTypeList[obj.customerType]}`);
  }

  if (obj.latestSignedOrderMiniScene) {
    list.push(`来源场景值：${obj.latestSignedOrderMiniScene}`);
  }

  if (obj.miniType) {
    let str = getMiniTypeList(obj.miniType);
    list.push(`来源平台：${str}`);
  }

  if (obj.originalOrderMiniType) {
    let str = getMiniTypeList(obj.originalOrderMiniType);
    list.push(`最早下单平台：${str}`);
  }

  if (obj.latestOrderMiniType) {
    let str = getMiniTypeList(obj.latestOrderMiniType);
    list.push(`最近下单平台：${str}`);
  }

  if (obj.orderNo) {
    list.push(`订单编号：${obj.orderNo}`);
  }

  if (obj.itemShortName) {
    list.push(`商品简称：${obj.itemShortName}`);
  }

  if (obj.miniScene) {
    list.push(`来源场景值：${obj.miniScene}`);
  }

  if (obj.quotientId || obj.latestSignedOrderQuotientId) {
    list.push(`导流商ID：${obj.quotientId || obj.latestSignedOrderQuotientId}`);
  }

  if (obj.latestSignedOrderStatus) {
    let str = getOrderStatusStr(obj.latestSignedOrderStatus);
    list.push(`订单状态：${str}`);
  }

  if (obj.listType && showListType) {
    let listTypeStatus = {
      list: '用户列表',
      preLoan: '贷前用户',
      inLoan: '贷中用户',
      postLoan: '贷后用户'
    };
    let str = listTypeStatus[obj.listType];
    list.push(`用户类型：${str}`);
  }

  if (obj.isRepurchase == 0 || obj.isRepurchase == 1) {
    list.push(`复购状态：${obj.isRepurchase == 0 ? '未复购' : '已复购'}`);
  }

  if (obj.isSms !== undefined) {
    list.push(`是否发送短信：${obj.isSms == 1 ? '是' : '否'}`);
  }

  if (obj.ascOrDesc !== undefined) {
    list.push(`顺序：${obj.ascOrDesc == 'asc' ? '正序' : '倒序'}`);
  }

  if (obj.sourceType) {
    let listTypeStatus = {
      1: '系统选取',
      2: 'excel导入',
      3: '圈选'
    };
    list.push(`${listTypeStatus[obj.sourceType]}`);
  }

  return list.join(',');
}
export function setWaterMark(str) {
  const dom = document.getElementById('waterMarkContent');
  if (dom) document.body.removeChild(dom);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = 250;
  canvas.height = 180;
  context.font = '100 14px "PingFangSC", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif';
  context.fillStyle = 'rgba(0,0,0,0.2)';
  context.textAlign = 'center';
  const textArry = str; // for (const i in str) {
  //   if (context.measureText(textArry[textArry.length - 1]).width > 160) {
  //     textArry.push([str[i]])
  //   } else {
  //     textArry[textArry.length - 1] = textArry[textArry.length - 1] + str[i]
  //   }
  // }

  context.rotate(-45 * Math.PI / 180);
  const baseHeight = (4 - textArry.length) * 35 + 70;
  textArry.forEach((item, i) => {
    context.fillText(item, 25 - textArry.length * 5, 21 * i + baseHeight, 160);
  });
  const waterMark = document.createElement('div');
  waterMark.id = 'waterMarkContent';
  waterMark.style.pointerEvents = 'none';
  waterMark.style.position = 'fixed';
  waterMark.style.top = '0';
  waterMark.style.left = '200px';
  waterMark.style.right = '0';
  waterMark.style.bottom = '0';
  waterMark.style.zIndex = '999999';
  waterMark.style.backgroundImage = `url(${canvas.toDataURL('image/png')})`;
  document.body.append(waterMark);
}
export function createUuid() {
  var s = [];
  var hexDigits = '0123456789abcdef';

  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }

  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

  s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

  s[8] = s[13] = s[18] = s[23] = '-';
  var uuid = s.join('');
  return uuid;
}
export function transUploadParams(qiniuDomain, qiniuKey) {
  return {
    name: qiniuKey,
    uid: createUuid(),
    url: qiniuDomain + qiniuKey,
    response: {
      data: {
        qiniuKey
      }
    }
  };
}
/**
 * 生成微信小程序scheme
 * @author chenkuo
 * @date 2022-11-29
 * @param {any} params
 * @returns {string}
 */

export function createWxScheme(params) {
  const {
    env = '',
    pageQuery,
    globalQuery = {},
    path = {},
    miniType
  } = params;
  const mergeParams = { ...pageQuery,
    ...globalQuery
  };
  let queryParams = '';
  Object.keys(mergeParams).forEach(item => {
    queryParams += `${encodeURIComponent(`${item}=${mergeParams[item]}`)}&`;
  });
  const jumpLink = `https://static.youpinhaoche.com/mini/to_wx.html?path=${path}&miniType=${miniType}&query=${queryParams}&env=${env}`;
  return {
    jumpLink
  };
}
/**
 * 生成支付宝小程序scheme
 * @author chenkuo
 * @date 2022-11-29
 * @param {any} params
 * @returns {string}
 */

export function createAlipayScheme(params) {
  const {
    env = '',
    pageQuery = {},
    globalQuery = {},
    path,
    appId
  } = params;
  let page = `${path}`;
  Object.keys(pageQuery).forEach((item, index) => {
    page += encodeURIComponent(`${index === 0 ? '?' : ''}${item}=${pageQuery[item]}&`);
  });
  let otherQuery = '';
  Object.keys(globalQuery).forEach(item => {
    otherQuery += `${encodeURIComponent(`${item}=${globalQuery[item]}`)}&`;
  });
  const jumpLink = `alipays://platformapi/startapp?appId=${appId}&page=${page}&query=${otherQuery}&env=${env}`;
  const ultimatelyJumpLink = `https://ds.alipay.com/?scheme=${encodeURIComponent(`${jumpLink}`)}`;
  return {
    jumpLink,
    ultimatelyJumpLink
  };
}
/**
 * 生成跳转链接
 * @author chenkuo
 * @date 2022-11-29
 * @param {any} params={}
 * @returns {void}
 */

export function schemeToMiniProgram(params = {}) {
  const {
    miniType,
    pageQuery = {},
    globalQuery = {},
    path,
    env
  } = params;
  const miniTypeEnum = MiniProgramType;
  const createLinkEnum = {
    wx: createWxScheme,
    alipay: createAlipayScheme
  };

  if (!miniType) {
    alert('请传入跳转的小程序类型');
    return;
  }

  if (!path) {
    alert('请传入跳转的小程序链接');
    return;
  }

  if (!miniTypeEnum[miniType]) {
    alert('跳转的小程序类型不正确');
    return;
  }

  return createLinkEnum[miniTypeEnum[miniType].type]({
    miniType,
    pageQuery,
    globalQuery,
    path,
    appId: miniTypeEnum[miniType].appId,
    env
  });
}
export function getRequest(urlStr) {
  let url = decodeURI(location.search);

  if (urlStr) {
    url = '?' + urlStr.split('?')[1]; //获取url中"?"符后的字符串
  }

  let theRequest = new Object();

  if (url.indexOf('?') != -1) {
    let str = url.substr(1);
    let strs = str.split('&');

    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
    }
  }

  return theRequest;
}